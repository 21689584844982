import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
//import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
const Delete = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box>
        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Paper
              elevation={3}
              style={{
                textAlign: 'center',
                width: '500px',
                height: '700px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Grid container direction="row" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
                <Grid>
                <IconButton aria-label="delete" size="large">
        <DeleteIcon fontSize="inherit" />
      </IconButton>
                  <Typography variant="h6" gutterBottom component="div" color="red">
                    DELETE
                  </Typography>
                  <Button
                    variant="outlined"
                    endIcon={<ArrowBackIosIcon/>}
                    onClick={() => {
                      navigate('/');
                    }}
                    color="warning"
                  >
                    Go back
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Delete;
