import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

const Success = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box>
        <Grid 
        container direction="column" 
        justifyContent="center" 
        alignItems="center" 
        style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Paper
              elevation={3}
              style={{
                textAlign: 'center',
                width: '500px',
                height: '700px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Grid container direction="row" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
                <Grid>
                  <CheckCircleIcon color="success" 
                  style={{ height: 50, 
                  width: 30 }} />
                  <Typography variant="h6" gutterBottom component="div" color="green">
                    Success
                  </Typography>
                  <Button
                    variant="outlined"
                    endIcon={<ArrowBackIosIcon/>}
                    onClick={() => {navigate('/');}}
                    color="success"
                  >
                    Go back
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Success;
