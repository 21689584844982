import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams } from 'react-router-dom';
import den from '../src/42557.jpg';
const Edit = () => {
  let { id } = useParams();

  const [toDoList, setTodoList] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    const todoList = localStorage.getItem('toDoList');

    if (todoList) {
      setTodoList(JSON.parse(todoList));

      setValue(JSON.parse(todoList)[id]);
    }
  }, [id]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const saveValue = () => {
    const list = [...toDoList];

    list[id] = value;

    setTodoList(JSON.stringify(list));

    localStorage.removeItem('toDoList');

    localStorage.setItem('toDoList', JSON.stringify(list));

    navigate('/');
  };

  const navigate = useNavigate();

  return (
    <>
      <Box>
        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Paper
              elevation={3}
              style={{
                textAlign: 'center',
                width: '500px',
                height: '700px',
                marginTop: '10px',
                marginBottom: '10px',
            
              }}
            >
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: '60%', height: '60%', padding: 20 }}
              >
                <Grid item>
                  <Typography variant="h6" gutterBottom component="div" color="primary">
                    EDIT TASK<br/>
                    ----------
                  </Typography>
                  <TextField 
                    style={{ width: '400px' }}
                    id="outlined-basic"
                    label="Type something"
                    variant="outlined"
                    value={value}
                    onChange={(event) => handleChange(event)}
                  />
                  <Button variant="contained" style={{ marginTop: 15 }} onClick={saveValue}>
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    endIcon={<ArrowBackIosIcon />}
                    onClick={() => {
                      navigate('/');
                    }}
                    color="success"
                  >
                    Go back
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

      </Box>
    </>
  );
};

export default Edit;
