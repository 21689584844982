import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App.jsx';
import Delete from './Delete';
import Success from './Success';
import Edit from './Edit';
// render(
//   <BrowserRouter>
//     <Routes>
//        <Route path="/" element={<App />} />
//       <Route path="/success" element={<Success />} />
//       <Route path="/delete" element={<Delete />} />
//       <Route path="/edit/:id" element={<Edit />} />
//     </Routes>
//   </BrowserRouter>,
//   document.getElementById('root')
// );
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/success" element={<Success />} />
      <Route path="/delete" element={<Delete />} />
       <Route path="/edit/:id" element={<Edit />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
