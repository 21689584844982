// import React from 'react'
// import Button from '@mui/material/Button'
// import TextField from '@mui/material/TextField'
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import {lightBlue, red} from '@mui/material/colors'

// import styled  from '@emotion/styled';
// import Grid from '@mui/material/Grid';
// import  Typography  from '@mui/material/Typography';
// import  IconButton  from '@mui/material/IconButton';
// import  Addicon from '@mui/icons-material/Add';
// import StyledIconButton from '@mui/material/IconButton';
// const item=styled(Paper)(({theme}) =>({
//   ...theme.typography.body2,
//   padding:theme.spacing(2),
//   textAlign:'center',
//   color:theme.palette.text.secondary,
// height:'700px',
// width:'30%',
// marginTop:'8px',
// marginBottom:'6px',
// }));
// const TextFieldStyled=styled(TextField)(({theme})=> ({
//   width:'100%',
// }));
// const TextFieldStyle=styled(TextField)(({theme})=> ({
//   width:'100%',
// }));
// const TextFeildStyledButton=styled(TextField)(({theme})=>({
//   position:'absolute',
//   Top:'20px',
//   left:'90000%',
//   marginTop:'600px',
//   marginLeft:'10000%',
//   width:'1000%',
//   padding:'90%',
//   }));

// const App = () => {
//   return (
//     <Box
//       sx={{
//          display: 'flex',
//          textAlign:'center',
//          flexWrap: 'wrap',
//         '& > :not(style)': {
//           m: 1,
//            width: 500,
//            height: 700,
//            border: 'dotted',
//            color:'darkred'
//         //  },
//        }}
//      >
//               <Grid container spacing={6}
//         direction="row" justifyContent="center"
//         alignItems="center">
//           <Grid item xs={6}>
//             <item elevation={10}>
//               <Grid container 
//               spacing={16} direction="column"
//               justifyContent="space-between">
//                 <Grid item xs={16}>
//                   <Typography varient="h6"
//                   gutterBottom component="div"><br/><br/><br/><br/><br/>
//                   <b Style="color:red">PLEASE ENTER YOUR CREDENTIALS</b>
//                   </Typography><br/><br/><br/>
//                   <TextFieldStyled id="outlined-basic" label="Type something" varient="outlined"/><br/><br/><br/><br/>
//                   <TextFieldStyle id="outlined-basic" label="Type something" varient="outlined"/>
//                   </Grid> 
//                   <Grid>
//                     <StyledIconButton color="primary" aria-label="Add task" component="span">
//                       <Addicon/>
//                       </StyledIconButton>
//                   </Grid>
//               </Grid>
//             </item>
//           </Grid>
//         </Grid>
//        <Paper elevation={0} /> 
//       </Box>
//   );
// }
// export default App
// import React, { useState, useEffect } from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import AddIcon from '@mui/icons-material/Add';
// import Icon from '@mui/material/Icon';
// import deleteButton from '@mui/icons-material/Delete';
// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',

//   height: '700px',
//   marginTop: '10px',
//   marginBottom: '10px',
// }));

// const TextFieldStyled = styled(TextField)(({ theme }) => ({
//   width: '100%',
// }));

// const StyledIconButton = styled(IconButton)(({ theme }) => ({}));
// const StyledIconButtons = styled(IconButton)(({ theme }) => ({}));
// const StyleGrid = styled(Grid)(({ theme }) => ({}));
// const StyleGrids = styled(Grid)(({ theme }) => ({}));
// const App = () => {
//   const [textFieldValue, setTextFieldValue] = useState('');

//   const handleChange = (event) => {
//     setTextFieldValue(event.target.value);
//   };

//   const [toDoList, setTodoList] = useState([]);

//   useEffect(() => {
//     const todoList = localStorage.getItem('toDoList');

//     console.log(todoList, 'todoList');

//     if (todoList) {
//       setTodoList(JSON.parse(todoList));
//     }
//   }, []);

//   const handleAdd = () => {
//     if (textFieldValue.length > 0) {
//       let newToDoList = [...toDoList];

//       newToDoList.pop(textFieldValue);

//       setTodoList(newToDoList);
//       setTextFieldValue('');

//      // clear 'toDoList' from local storage

//       localStorage.removeItem('toDoList');

//       console.log(toDoList);

     // save to local storage
//       localStorage.setItem('toDoList', JSON.stringify(newToDoList));
//     }
//   };

//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
//         <Grid item xs={8}>
//           <Item elevation={3}>
//             <StyleGrid
//               container
//              direction="column"
//               alignItems="stretch"
//               justifyContent="space-between"
//               flexWrap="nowrap"

//               style={{
               
//               height: '50%',
              
              
//               }}
//               alignContent="center"
//                          >
                         
//               <Grid item xs={12}>
//                 <Typography variant="h6" gutterBottom component="div">
//                   Add you task
//                 </Typography>
//                 <TextFieldStyled
//                   value={textFieldValue}
//                   id="outlined-basic"
//                   label="Type something"
//                   variant="outlined"
//                   onChange={(event) => handleChange(event)}
//                 />

//                 {toDoList.map((item, index) => {
//                   return (
//                     <Typography
//                       key={index}
//                       style={{ marginTop: '5px', marginLeft: '5px', textAlign: 'initial', color: 'blue',
                     
//                    }}
//                       variant="h6"
//                       gutterBottom
//                       component="div"
//                     >
//                       {item}
//                     </Typography>
//                   );
//                 })}
//               </Grid>

//               <Grid item>
//                 <StyledIconButton color="primary" aria-label="Add task" component="span" onClick={handleAdd}
                
//                 >
//                    <StyledIconButtons color="primary" aria-label="Add task" component="span" onClick={handleAdd}
                
//                 >
//                    <deleteButton />
//                   <AddIcon />
                 
//                   </StyledIconButtons>
//                   </StyledIconButton>
                
//               </Grid>
//             </StyleGrid>
//           </Item>
//         </Grid>
//       </Grid>
      
//     </Box>
//   );
// };
import React, { useState, useEffect,img,useRef } from 'react';
//import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
//import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import {lightGreen}  from '@mui/material/colors';
//import den from '../src/icons8-open-box-16.png';
import den from '../src/download (44).png';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
//import IconButton from '@mui/material/IconButton';
//import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/AttachFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
//import imagechinese from '../../assets/images/images(15).png'
//import imagefish from '../../assets/images/images (1)(4)(seafoods(fish fry).jpg'
import gmail from '../src/icons8-gmail-48.png';
import googlephotos from '../src/download (43).png';
import document from '../src/888034.png';
import location from '../src/images (3).jpg';
import Icon from '@mui/material/Icon';
import Alert from '@mui/material/Alert';
// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   height: '700px',
//   marginTop: '10px',
//   marginBottom: '10px',
// }));

// const TextFieldStyled = styled(TextField)(({ theme }) => ({
//   width: '100%',
// }));
// const TextFieldStyleds = styled(TextField)(({ theme }) => ({
//   width: '100%',
// }));

// const StyledIconButton = styled(IconButton)(({ theme }) => ({}));

// const StyleGrid = styled(Grid)(({ theme }) => ({}));

// const App = () => {
//   const [textFieldValue, setTextFieldValue] = useState('');

//   const handleChange = (event) => {
//     setTextFieldValue(event.target.value);
//   };

//   const [toDoList, setTodoList] = useState([]);

//   useEffect(() => {
//     const todoList = localStorage.getItem('toDoList');

//     console.log(todoList, 'todoList');

//     if (todoList) {
//       setTodoList(JSON.parse(todoList));
//     }
//   }, []);

//   const navigate = useNavigate();

//   const handleAdd = () => {
  
//     if (textFieldValue.length > 0) {
//       let newToDoList = [...toDoList];

//       newToDoList.push(textFieldValue);

//       setTodoList(newToDoList);
//       setTextFieldValue('');
//       //clear 'toDoList' from local storage

//       localStorage.removeItem('newToDoList');

//       console.log(newToDoList);

//       //save to local storage
//         localStorage.setItem('toDoList', JSON.stringify(newToDoList));

//       navigate('/success');
//     }
//   };
  
//   const handleAdds = () => {
  
//     if (textFieldValue.length > 0) {
//       let newToDoList = [...toDoList];

//       newToDoList.pop(textFieldValue);

//       setTodoList(newToDoList);
//       setTextFieldValue('');
//       //clear 'toDoList' from local storage

//       localStorage.removeItem('toDoList');

//       console.log(toDoList);

//       //save to local storage
//         localStorage.setItem('toDoList', JSON.stringify(newToDoList));

//       navigate('/delete');
//     }
//   };
  
//   const handleDelete = (index) => {
//     let newToDoList = [...toDoList];
//     newToDoList.splice(index, 1);
//     setTodoList(newToDoList);
//     localStorage.setItem('toDoList', JSON.stringify(newToDoList));
//     navigate('/delete');
     
//   };
  
//   const handleEdit = (index) => {
      
//     navigate(`/edit/${index}`)
    
//   };
  
//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch" backgroundColor="orange;">
//         <Grid item xs={8}>
//           <Item elevation={3}>
//             <StyleGrid
//               container
//               direction="column"
//               alignItems="stretch"
//               justifyContent="space-between"
//               flexWrap="nowrap"
//               style={{
//                 height: '100%',
//               }}
//               alignContent="center"
//             >
//               <Grid item xs={12}>
//                 <Typography variant="h6" gutterBottom component="div" color="blue;">
//                   ADD USERNAME OR NUMBER
//                 <h1>____________________</h1>
//                 </Typography>
//                 <TextFieldStyled
//                   value={textFieldValue}
//                   id="outlined-basic"
//                   label="         TYPE USER ANY DETAILS"
//                           __________________________
//                   variant="outlined"
//                   onChange={(event) => handleChange(event)}
//                 /><br/><br/>
                
//                 <TextFieldStyled
//                   value={textFieldValue}
//                   id="outlined-basic"
//                   label=        "TYPE USER ANY ANOTHER DETAILS"
//                   variant="outlined"
//                   onChange={(event) => handleChange(event)}
//                 />

//                 {toDoList.map((item, index) => {
//                   return (
//                     <Grid container direction="row" flexWrap="wrap" justifyContent="space-between" alignItems="baseline" style={{ padding: 5 }} key={index}>
//                       <Grid item>
//                         <Typography key={index} variant="h6" gutterBottom component="div" style={{ color: 'Blue' }}>
//                           {item}
//                         </Typography>
//                       </Grid>
//                       <Grid item>
//                         <IconButton color="error" aria-label="upload picture" component="span-between" onClick={() => handleAdds(index)}>
//                           <DeleteIcon />
//                         </IconButton>
//                       </Grid>
//                       <IconButton color="error" aria-label="upload picture" component="span" onClick={() => handleDelete(index)}>
//                           <DeleteIcon />
//                         </IconButton>
//                         <IconButton varient='contained' style={{marginTop:'50px'}} color="error" aria-label="Edit" component="span" onClick={() => handleEdit(index)}>
//                           <EditIcon />
//                         </IconButton>
//                     </Grid>
//                   );
//                 })}
//               </Grid>
//               <StyledIconButton color="primary" aria-label="Add task" component="span" onClick={handleAdd}>
//                   <AddIcon />
//               </StyledIconButton>
              
//             </StyleGrid>
//           </Item>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  height: '700px',
  marginTop: '10px',
  marginBottom: '10px',
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
 position:'absolute',
 top:'100px',
  width: '20%',
  left:'610px',
  backgroundColor:'lightpink',
 
}));
// const TextFieldStyleds = styled(TextField)(({ theme }) => ({
//   width: '100%',
  
// }));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position:'absolute',
 width: '50%',
  top:'108px',
   left:'560px',
}));

const StyleGrid = styled(Grid)(({ theme }) => ({}));
const ExpandMores = styled((props) => {
  const { expands, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expands }) => ({
  transform: !expands ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
const ExpandMore = styled((props) => {
  const { expandth, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
const ExpandMoredetail = styled((props) => {
  const { expandes, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expandes }) => ({
  transform: !expandes ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const App = () => {
  const [textFieldValue, setTextFieldValue] = useState('');
  const [expand, setExpand] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [expendext,setExpendext]=React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
    const handleExpandClicks = () => {
      setExpand(!expand);
    };
    const handleExpandClickes = () => {
      setExpendext(!expendext);
    };
  const handleChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const [toDoList, setTodoList] = useState([]);

  useEffect(() => {
    const todoList = localStorage.getItem('toDoList');

    console.log(todoList, 'todoList');

    if (todoList) {
      setTodoList(JSON.parse(todoList));
    }
  }, []);

  const navigate = useNavigate();

  const handleAdd = () => {
  
    if (textFieldValue.length > 0) {
      let newToDoList = [...toDoList];

      newToDoList.push(textFieldValue);

      setTodoList(newToDoList);
      setTextFieldValue('');
      //clear 'toDoList' from local storage

      localStorage.removeItem('newToDoList');

      console.log(newToDoList);

      //save to local storage
        localStorage.setItem('toDoList', JSON.stringify(newToDoList));

      navigate('/success');
    }
  };
  
  const handleAdds = () => {
  
    if (textFieldValue.length > 0) {
      let newToDoList = [...toDoList];

      newToDoList.pop(textFieldValue);

      setTodoList(newToDoList);
      setTextFieldValue('');
    //  clear 'toDoList' from local storage

      localStorage.removeItem('toDoList');

      console.log(toDoList);

     // save to local storage
        localStorage.setItem('toDoList', JSON.stringify(newToDoList));

      navigate('/delete');
    }
  };
  
  const handleDelete = (index) => {
    let newToDoList = [...toDoList];
    newToDoList.splice(index, 1);
    setTodoList(newToDoList);
    localStorage.setItem('toDoList', JSON.stringify(newToDoList));
    navigate('/delete');
     
  };
  
  const handleEdit = (index) => {
      
    navigate(`/edit/${index}`)
    
  };
  const videoRef=useRef(null);
  const photoRef=useRef(null);
  const [hasPhoto,setHasPhoto]=useState(false);
  const getVideo=() => {
  navigator.mediaDevices
  .getUserMedia({
  video:{width:300,height:300}
  })
  .then(stream => {
  let video=videoRef.current;
  video.srcObject=stream;
  video.play();
  })
  .catch(err => {
  console.error(err);
  })
  }
  const getclose=() => {
    navigator.mediaDevices
    .getUserMedia({
    video:{width:300,height:300}
    
    })
    .then(stream => {
    let video=videoRef.current;
    video.srcObject=stream;
    stream.getclose();
    })
    .catch(err => {
    console.error(err);
    })
  }
  const takePhoto=() => {
  const width=414;
  const height=width/(16/9);
  
  let video =videoRef.current;
  let photo=photoRef.current;
  photo.width= width;
  photo.height= height;
  let ctx=photo.getContext('2d');
  ctx.drawImage(video,0,0,width,height);
  setHasPhoto(true);
  }
  const closePhoto = () => {
  let photo = photoRef.current;
  let ctx=photo.getContext('2d');
  ctx.clearRect(0,0,photo.width,photo.height);
  setHasPhoto(false);
  }
  useEffect(() => {
  //getVideo();
  },[videoRef]);
      
  
  return (
    
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch" backgroundColor="red" required>
        <Grid item xs={8}>
          <Item elevation={3}>
         
            <StyleGrid
              container
              direction="column"
              alignItems="stretch"
              justifyContent="space-between"
              flexWrap="nowrap"
              style={{
                height: '100%',
              }}
              alignContent="center"
            >
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom component="div" color="blueviolet ">
                
                  Add your task<br/>
                  --------------------
                </Typography>
                
                {toDoList.map((item, index) => {
                  return (
                    <Grid container direction="row" flexWrap="wrap" justifyContent="space-between" alignItems="baseline" style={{ padding: 5 }} key={index} required>
                      <Grid item>
                        <Typography key={index} variant="h6" gutterBottom component="div" style={{ backgroundColor: 'lightgreen' }} required>
                          {item} 
                          
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton color="info" aria-label="upload picture" component="span" onClick={() => handleDelete(index)} required>
                        
                          <DeleteIcon/>
                        </IconButton>
                     </Grid>
                      <Grid item>
                      <IconButton color="error" aria-label="upload picture" component="span" onClick={() => handleAdds(index)} required>
                         <DeleteIcon />
                        </IconButton>
                       </Grid>
                   <IconButton varient='contained' style={{marginTop:'50px'}} color="warning" aria-label="Edit" component="span" onClick={() => handleEdit(index)} required>
                    < EditIcon />
                  </IconButton>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item>
                <StyledIconButton color="primary" aria-label="Add task" component="span" onClick={handleAdd()}>
                  <AddIcon/>
                </StyledIconButton>
                {/* <a href={handleAdd()}>  */}
                
  
                <TextFieldStyled
                  value={textFieldValue}
                  backgroundColor={lightGreen}
                  id="outlined-basic"
                  label="Type something"
                  variant="outlined"
                  onChange={(event) => handleChange(event)} required
                />
                
  <div className="App">
  <div className="camera">
  <video ref={videoRef}></video>
  <Button onClick={takePhoto}>TAKEPHOTO</Button>
  </div>
    <div className={'result ' + (hasPhoto ? 'hasPhoto' : '')}>
  <canvas ref={photoRef}></canvas>
  <Button onClick={closePhoto}>RESET PHOTO</Button>
  <Button onClick={getVideo}></Button>
  <Button onClick={getclose}>CUTOFF</Button>
  <img src={den} onClick={getVideo}></img>
    </div>
  </div>
               </Grid>
            </StyleGrid>
          </Item>
        </Grid>
      </Grid>
      <div className='photo'> 
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        action={
         <IconButton aria-label="settings">
           {/* <MoreVertIcon /> */}
           </IconButton>
       }
       />
          <CardContent>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="share" 
         expandes={expendext}
         onClick={handleExpandClickes}
                     >
        <ShareIcon color="success"/>
        </IconButton> 
               </CardActions>
     <Collapse in={expendext} timeout="auto" unmountOnExit>
       <CardContent>
               <a href="https://mail.google.com/mail/u/0/#inbox"> 
        <img src={gmail}/></a>
        <a href="https://www.google.com/maps/@37.7765141,-122.4528353,13z"> 
        <img src={location}/></a> 
        <a href="https://accounts.google.com/InteractiveLogin/signinchooser?continue=https%3A%2F%2Fphotos.google.com%2Flogin%3Futm_campaign%3DmainCTA%26utm_medium%3Dwebsite%26utm_source%3Dmarketingsite&followup=https%3A%2F%2Fphotos.google.com%2Flogin%3Futm_campaign%3DmainCTA%26utm_medium%3Dwebsite%26utm_source%3Dmarketingsite&osid=1&passive=1209600&service=lh2&ifkv=ASKXGp3usc93rX5kN0sezzuXNPK5eyB9jQy-IzbLO4xBSquiorX1YTwqYe3rvlNGh0_TIpFnOaAz&theme=glif&flowName=GlifWebSignIn&flowEntry=ServiceLogin"> 
        <img src={googlephotos}/></a>
        <a href="https://www.adobe.com/in/acrobat/mobile/scanner-app.html"> 
        <img src={document}/></a>
            <Typography>
                 </Typography>
              </CardContent>
     </Collapse>
        </Card>
       </div>  
    </Box>
  );
};

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',

//   height: '700px',
//   marginTop: '10px',
//   marginBottom: '10px',
// }));

// const TextFieldStyled = styled(TextField)(({ theme }) => ({
//   width: '100%',
// }));

// const StyledIconButton = styled(IconButton)(({ theme }) => ({}));

// const StyleGrid = styled(Grid)(({ theme }) => ({}));

// const App = () => {
//   const [textFieldValue, setTextFieldValue] = useState('');

//   const handleChange = (event) => {
//     setTextFieldValue(event.target.value);
//   };

//   const [toDoList, setTodoList] = useState([]);

//   useEffect(() => {
//     const todoList = localStorage.getItem('toDoList');

//     if (todoList) {
//       setTodoList(JSON.parse(todoList));
//     }
//   }, []);

//   const navigate = useNavigate();

//   const handleAdd = () => {
//     if (textFieldValue.length > 0) {
//       let newToDoList = [...toDoList];

//       newToDoList.push(textFieldValue);

//       setTodoList(newToDoList);
//       setTextFieldValue('');

//       //clear 'toDoList' from local storage

//       localStorage.removeItem('toDoList');

//       console.log(newToDoList);

//       //save to local storage
//       localStorage.setItem('toDoList', JSON.stringify(newToDoList));

//       navigate('/success');
//     }
//   };

//   const handleEdit = (index) => {
//     navigate(`/edit/${index}`);
//   };

//   const handleDelete = (index) => {
//     let newToDoList = [...toDoList];
//     newToDoList.splice(index, 1);
//     setTodoList(newToDoList);
//     localStorage.setItem('toDoList', JSON.stringify(newToDoList));
//   };

//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
//         <Grid item xs={8}>
//           <Item elevation={3}>
//             <StyleGrid
//               container
//               direction="column"
//               alignItems="stretch"
//               justifyContent="space-between"
//               flexWrap="nowrap"
//               style={{
//                 height: '100%',
//               }}
//               alignContent="center"
//             >
//               <Grid item xs={12}>
//                 <Typography variant="h6" gutterBottom component="div">
//                   Add you task
//                 </Typography>
//                 <TextFieldStyled
//                   value={textFieldValue}
//                   id="outlined-basic"
//                   label="Type something"
//                   variant="outlined"
//                   onChange={(event) => handleChange(event)}
//                 />

//                 {toDoList.map((item, index) => {
//                   return (
//                     <Grid
//                       container
//                       direction="row"
//                       flexWrap="wrap"
//                       justifyContent="space-between"
//                       alignItems="baseline"
//                       style={{ padding: 5 }}
//                       key={index}
//                     >
//                       <Grid item>
//                         <Typography key={index} variant="h6" gutterBottom component="div" style={{ color: 'green' }}>
//                           {item}
//                         </Typography>
//                       </Grid>
//                       <Grid item>
//                         <IconButton color="primary" aria-label="Edit" component="span" onClick={() => handleEdit(index)}>
//                           <EditIcon />
//                         </IconButton>
//                         <IconButton color="error" aria-label="Delete" component="span" onClick={() => handleDelete(index)}>
//                           <DeleteIcon />
//                         </IconButton>
//                       </Grid>
//                     </Grid>
//                   );
//                 })}
//               </Grid>

//               <Grid item>
//                 <StyledIconButton color="primary" aria-label="Add task" component="span" onClick={handleAdd}>
//                   <AddIcon />
//                 </StyledIconButton>
//               </Grid>
//             </StyleGrid>
//           </Item>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

<div className='d'>
<a><img src={den}></img></a>
       </div>
export default App;